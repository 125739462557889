import React from "react";
import Navbar from "../sections/Navbar/Navbar";
// @ts-ignore
import Seo from "../utils/seo";
import IndividualCoachingHero from "../sections/IndividualCoachingHero";
import Benefits from "../sections/Benefits";
import Example from "../sections/Example";
import ProgramHelp from "../sections/ProgramHelp";
import Faq from "../sections/Faq";
import Footer from "../sections/Footer";
// @ts-ignore
import Layout from "../layouts";
import Leaders from "../sections/Leaders";
const IndividualCoaching = () => {
  return (
    <>
      <Seo title="Individual Coaching for Personal Growth and Success" description="Empower yourself with tailored individual coaching by Coachello. Achieve clarity, purpose, and results in all life’s areas."
        // image={seoImg}
      />
      <Layout>
      <IndividualCoachingHero />
      <Benefits />
      <Example />
      <ProgramHelp />
      <Leaders />
      <Faq />
      </Layout>
    </>
  );
};

export default IndividualCoaching;
